export default [
  {
    __config__: {
      label: "作业说明",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: "tinymce",
      tagIcon: "rich-text",
      defaultValue: null,
      span: 24,
      layout: "colFormItem",
      required: true,
      regList: [],
      document: "http://tinymce.ax-z.cn"
    },
    placeholder: "请输入作业说明",
    height: 300, // 编辑器高度
    branding: false, // 隐藏右下角品牌烙印
    __vModel__: "explain"
  }
]
